<template>
  <div class="my-container">
    <div class="item tool-bar" >
      <CRow>
        <CCol>
          <span style="padding-left: 2mm">医療機関名:{{printHeader.hospitalName}}</span>
        </CCol>
        <CCol class="text-right">
          グラフ表示期間:{{printHeader.printFormTo}} &nbsp;&nbsp; 印刷日:{{ printHeader.toDay }}
        </CCol>
      </CRow>
      <CRow>
        <CCol class="pl-3">
          <span style="padding-left: 2mm">処方医:{{printHeader.doctorName}} </span>
        </CCol>
        <CCol class="text-right">
          販売店:{{printHeader.agencyName}} {{printHeader.agencyBranchName}}
        </CCol>
      </CRow>
    </div>
        <div class="left mt-2">
          <div>
            <span style="width:15mm;display: inline-block;font-size: 4mm;color: black;">ID</span>
            <span style="font-size: 4mm;color: black;">{{karuteNo}}</span>
          </div>

          <div>
            <span style="width: 15mm;display: inline-block;font-size: 4mm;color: black;">氏名</span>
            <span style="font-size: 4mm;color: black;">{{patientName}}</span>
          </div>

          <div class="patient-info no-print-area mt-3" >
            <div class="patient-info-item">処方流量(リットル/分)</div>
          </div>
          <CRow class="patient-info no-print-area" >
            <CCol class="patient-info-item ml-3 text-center">安静時</CCol>
            <CCol class="patient-info-item ml-3 text-center">労作時</CCol>
            <CCol class="patient-info-item ml-3 text-center">就寝時</CCol>
          </CRow>

          <CRow class="patient-info no-print-area" >
            <CCol class="patient-info-item ml-3 text-center">{{ prescription.rest }}</CCol>
            <CCol class="patient-info-item ml-3 text-center">{{ prescription.exercise }}</CCol>
            <CCol class="patient-info-item ml-3 text-center">{{ prescription.sleep }}</CCol>
          </CRow>

          <table width="100%" class="mt-5" style="color:black;">
            <tr v-if="chartType == 'summary' || chartType == 'ox'">
              <td colspan="6" style="border:none;"><label style="font-weight: bold">酸素濃縮装置</label></td>
            </tr>
            <tr class="data-label" v-if="chartType == 'summary' || chartType == 'ox'">
              <td></td>
              <td style="border:solid 1px black;" class="text-center">最大値</td>
              <td class="text-center">最小値</td>
              <td class="text-center">平均値</td>
              <td class="text-center">中央値</td>
              <td class="text-center">標準偏差</td>
              <!--              <td>閾値外頻度1</td>-->

              <!--              <td colspan="2">装着時間(h)</td>-->
            </tr>
            <tr v-if="chartType == 'summary' || chartType == 'ox'">
              <td ><div class="ml-2">流量（リットル/分）</div></td>
              <td class="text-center">{{ measureValueSummary.ox_setting["max"] }}</td>
              <td class="text-center">{{ measureValueSummary.ox_setting["min"] }}</td>
              <td class="text-center">{{ measureValueSummary.ox_setting["avg"] }}</td>
              <td class="text-center">{{ measureValueSummary.ox_setting["middle"] }}</td>
              <td class="text-center">{{ measureValueSummary.ox_setting["standardDeviation"] }}</td>
              <!--              <td>{{ measureValueSummary.ox_setting["thresholdGai"] }}/{{ measureValueSummary.ox_setting["thresholdGaiBunshi"] }}</td>-->
              <!--              <td></td>-->
              <!--                      <td>{{ measureValueSummary.ox_setting["hour"] }}</td>-->
            </tr>
            <tr v-if="chartType == 'summary' || chartType == 'ox'">
              <td ><div class="ml-2">分時呼吸数(回/分)</div></td>
              <td class="text-center">{{ measureValueSummary.ox_breath["max"] }}</td>
              <td class="text-center">{{ measureValueSummary.ox_breath["min"] }}</td>
              <td class="text-center">{{ measureValueSummary.ox_breath["avg"] }}</td>
              <td class="text-center">{{ measureValueSummary.ox_breath["middle"] }}</td>
              <td class="text-center">{{ measureValueSummary.ox_breath["standardDeviation"] }}</td>
              <!--              <td>{{ measureValueSummary.ox_breath["thresholdGai"] }}/{{ measureValueSummary.ox_breath["thresholdGaiBunshi"] }}</td>-->
              <!--              <td></td>-->
              <!--                    <td>{{ measureValueSummary.ox_breath["hour"] }}</td>-->
            </tr>

            <tr v-if="chartType == 'summary' || chartType == 'ox'" >
              <!--              <td></td>-->
              <td colspan="6" style="border: none;">
<!--                <div class="text-center"><span></span>稼働時間:{{ measureValueSummary.ox_setting["hour"] }}{{oxHourMark}}<span class="ml-3">装着時間:{{ measureValueSummary.ox_breath["hour"]}}{{oxBreathHourMark}}</span><span class="ml-3">装着率:{{ souchakuritsu}}</span></div>-->
                <div class="text-center"><span></span>稼働時間:{{ measureValueSummary["kadouJikan"] }}{{oxHourMark}}
                  <span class="ml-3">装着時間:{{ measureValueSummary["souchakuJikan"]}}{{oxBreathHourMark}}</span>
                  <span class="ml-3">装着率:{{ souchakuritsu}}</span>
                </div>
                <div  style="font-size:11px;">※酸素濃縮装置により検出された数値を表示していますので、</div>
                <div  style="font-size:11px;padding-left: 8px">実際の数値とは異なる場合があります。</div>
              </td>
            </tr>
            <!--            <tr>-->
            <!--              <td colspan="8" style="color: #FFF">.</td>-->
            <!--            </tr>-->

            <tr>
              <td class="device" colspan="6" style="border:none;"><label style="color:transparent">-</label></td>
            </tr>

            <tr v-if="chartType == 'summary' || chartType == 'pu'" class="mt-3">
              <td class="device" colspan="6" style="border:none;"><label style="font-weight: bold">パルスオキシメータ</label></td>
            </tr>
            <tr class="data-label" v-if="chartType == 'summary' || chartType == 'pu'">
              <td></td>
              <td class="text-center">最大値</td>
              <td class="text-center">最小値</td>
              <td class="text-center">平均値</td>
              <td class="text-center">中央値</td>
              <td class="text-center">標準偏差</td>
              <!--              <td>閾値外頻度</td>-->
              <!--              <td>測定時間(m)</td>-->
            </tr>
            <tr v-if="chartType == 'summary' || chartType == 'pu'">
              <td ><div class="ml-2">血中酸素飽和度(％)</div></td>
              <td class="text-center">{{ measureValueSummary.spo2["max"] }}</td>
              <td class="text-center">{{ measureValueSummary.spo2["min"] }}</td>
              <td class="text-center">{{ measureValueSummary.spo2["avg"] }}</td>
              <td class="text-center">{{ measureValueSummary.spo2["middle"] }}</td>
              <td class="text-center">{{ measureValueSummary.spo2["standardDeviation"] }}</td>
              <!--              <td>{{ measureValueSummary.spo2["thresholdGai"] }}/{{ measureValueSummary.spo2["thresholdGaiBunshi"] }}</td>-->
              <!--              <td>{{ measureValueSummary.spo2["hour"] }}</td>-->
            </tr>
            <tr v-if="chartType == 'summary' || chartType == 'pu'">
              <td ><div class="ml-2">脈拍数(回/分)</div></td>
              <td class="text-center">{{ measureValueSummary.pr_bpm["max"] }}</td>
              <td class="text-center">{{ measureValueSummary.pr_bpm["min"] }}</td>
              <td class="text-center">{{ measureValueSummary.pr_bpm["avg"] }}</td>
              <td class="text-center">{{ measureValueSummary.pr_bpm["middle"] }}</td>
              <td class="text-center">{{ measureValueSummary.pr_bpm["standardDeviation"] }}</td>
              <!--              <td>{{ measureValueSummary.pr_bpm["thresholdGai"] }}/{{ measureValueSummary.pr_bpm["thresholdGaiBunshi"] }}</td>-->
              <!--              <td>{{ measureValueSummary.pr_bpm["hour"] }}</td>-->
            </tr>
            <tr v-if="chartType == 'summary' || chartType == 'pu'">
              <td colspan="7" style="border: none;"><div class="text-center">表示期間のパルスオキシメータ測定回数 {{ measureValueSummary.pr_bpm["measureCount"] }} 回</div></td>
            </tr>

            <!--            <tr>-->
            <!--              <td colspan="8" style="color: #FFF">.</td>-->
            <!--            </tr>-->
            <tr v-if="chartType == 'bl'">
              <td class="device" colspan="7" style="border: none;"><label style="font-weight: bold">血圧計</label></td>
            </tr>
            <tr class="data-label" v-if="chartType == 'bl'">
              <td></td>
              <td class="text-center">最大値</td>
              <td class="text-center">最小値</td>
              <td class="text-center">平均値</td>
              <td class="text-center">中央値</td>
              <td class="text-center">標準偏差</td>
              <!--              <td>閾値外頻度</td>-->
<!--              <td class="text-center">測定回数</td>-->
            </tr>
            <tr v-if="chartType == 'bl'">
              <td ><div class="ml-2">収縮期血圧(mmHg)</div></td>
              <td class="text-center">{{ measureValueSummary.bl_up["max"] }}</td>
              <td class="text-center">{{ measureValueSummary.bl_up["min"] }}</td>
              <td class="text-center">{{ measureValueSummary.bl_up["avg"] }}</td>
              <td class="text-center">{{ measureValueSummary.bl_up["middle"] }}</td>
              <td class="text-center">{{ measureValueSummary.bl_up["standardDeviation"] }}</td>
              <!--              <td>{{ measureValueSummary.bl_up["thresholdGai"] }}/{{ measureValueSummary.bl_up["thresholdGaiBunshi"] }}</td>-->
<!--              <td rowspan="3"><div class="text-center">{{ measureValueSummary.bl_up["hour"] }}</div></td>-->
            </tr>
            <tr v-if="chartType == 'bl'">
              <td ><div class="ml-2">拡張期血圧(mmHg)</div></td>
              <td class="text-center">{{ measureValueSummary.bl_down["max"] }}</td>
              <td class="text-center">{{ measureValueSummary.bl_down["min"] }}</td>
              <td class="text-center">{{ measureValueSummary.bl_down["avg"] }}</td>
              <td class="text-center">{{ measureValueSummary.bl_down["middle"] }}</td>
              <td class="text-center">{{ measureValueSummary.bl_down["standardDeviation"] }}</td>
              <!--              <td>{{ measureValueSummary.bl_down["thresholdGai"] }}/{{ measureValueSummary.bl_down["thresholdGaiBunshi"] }}</td>-->
              <!--              <td>{{ measureValueSummary.bl_down["hour"] }}</td>-->
            </tr>

            <tr v-if="chartType == 'bl'">
              <td ><div class="ml-2">脈拍数(回/分)</div></td>
              <td class="text-center">{{ measureValueSummary.bl_bpm["max"] || '' }}</td>
              <td class="text-center">{{ measureValueSummary.bl_bpm["min"] }}</td>
              <td class="text-center">{{ measureValueSummary.bl_bpm["avg"] }}</td>
              <td class="text-center">{{ measureValueSummary.bl_bpm["middle"] }}</td>
              <td class="text-center">{{ measureValueSummary.bl_bpm["standardDeviation"] }}</td>
              <!--              <td>{{ measureValueSummary.bl_bpm["thresholdGai"] }}/{{ measureValueSummary.bl_bpm["thresholdGaiBunshi"] }}</td>-->
              <!--              <td>{{ measureValueSummary.bl_bpm["hour"] }}</td>-->
            </tr>
            <tr v-if="chartType == 'bl'">
              <td colspan="6" style="border: none;"><div class="text-center">表示期間の血圧計の測定回数 {{ measureValueSummary.bl_up["hour"] }} 回</div></td>
            </tr>

          </table>
          <label class="mt-2 mb-0" style="font-size: 4mm;color: black;">メモ</label>
          <pre style="font-size: 4mm;color: black;border: 1px black solid;padding-left: 5px;width:100%;height:150px;">{{$store.state.memo}}

          </pre>
        </div>

        <div class="right">
          <div v-if="['summary','ox'].indexOf(chartType) >=0 ">
            <ox-charts7-print v-if="dayCount < 30" :day-count="dayCount"  :from-series="serieObject['OX_SETTING']"
                              :chart-width="widthHeight.OX_SETTING_WIDTH"
                              chart-height="170mm"
                              :x-title="oxChartXTitle" :y-title="oxChartYTitle" :day-from-to="dayFromTo"/>
            <ox-charts90-print v-else :day-count="dayCount"  :from-series="serieObject['OX_SETTING']"
                               :chart-width="widthHeight.OX_SETTING_WIDTH"
                               chart-height="170mm"
                               :x-title="oxChartXTitle" :y-title="oxChartYTitle" :day-from-to="dayFromTo"/>
          </div>
          <div v-if="chartType == 'summary'">
            <!-- 呼吸数 -->
            <div class="chart chart2">
              <ox-breath-charts-print v-if="dayCount < 30" :day-count="dayCount"
                            :from-series="serieObject['SUMMARY_OX_BREATH_17']" :day-from-to="dayFromTo" chart-title="呼吸数" :ymin="10" :ymax="35"
                            :x-title="breath17XTitle" y-title="回/分" chart-width="710" chart-height="170mm"/>
              <ox-breath-charts30-print v-else :day-count="dayCount" :from-series="serieObject['SUMMARY_OX_BREATH_3090']"
                        chart-width="710"  chart-height="170mm"    :day-from-to="dayFromTo" chart-title="呼吸数" :ymin="10" :ymax="35"
                              x-title="日付" y-title="回/分" />
            </div>
            <!-- 血中酸素飽和度○/脈拍数 -->
            <ox-spo2-charts-print v-if="dayCount < 30" :day-count="dayCount" chart-title="血中酸素飽和度/脈拍数"
                        :from-series="serieObject['SUMMARY_PU_SPO2_17']" :day-from-to="dayFromTo" :ymin="getSpo2YaxisPointMin(serieObject['SUMMARY_PU_SPO2_17'],80)" :ymax="100"
                        :x-title="spo17Title" y-title="%" :colors="['#6600FF']"
                               chart-width="710" chart-height="170mm"/>
            <ox-breath-charts30-print v-else :day-count="dayCount" chart-title="血中酸素飽和度/脈拍数" :from-series="serieObject['SUMMARY_PU_SPO2_3090']"
                            :day-from-to="dayFromTo" :ymin="getSpo2YaxisMin(serieObject['SUMMARY_PU_SPO2_3090'],80)" :ymax="100"
                            x-title="日付" y-title="%" :colors="['#6600FF']"
                                   chart-width="710" chart-height="170mm"/>
            <!-- 血中酸素飽和度/脈拍数○ -->
              <ox-pulse-charts-print v-if="dayCount < 30" :day-count="dayCount"
                           :from-series="serieObject['SUMMARY_PU_MYAKUHAKU_17']" :day-from-to="dayFromTo"
                           :ymin="getMyakuhakuYaxisPointMin(serieObject['SUMMARY_PU_MYAKUHAKU_17'],60)"
                           :ymax="getMyakuhakuYaxisPointMax(serieObject['SUMMARY_PU_MYAKUHAKU_17'],180)"
                           :x-title="spo17Title" y-title="回/分" :colors="['#FF0000']" chart-width="710" chart-height="170mm"
              />
              <ox-breath-charts30-print v-else style="margin-top: -40px" :day-count="dayCount" chart-title="" :from-series="serieObject['SUMMARY_PU_MYAKUHAKU_3090']"
                              :day-from-to="dayFromTo" :ymin="getMyakuhakuYaxisMin(serieObject['SUMMARY_PU_MYAKUHAKU_3090'],60)" :ymax="getMyakuhakuYaxisMax(serieObject['SUMMARY_PU_MYAKUHAKU_3090'],180)" :show-xasis="false" :colors="['#FF0000']"
                              y-title="回/分" chart-width="710" chart-height="170mm"/>
          </div>

          <!-- 酸素吸入-->
          <div v-show="chartType == 'ox'">
            <div class="chart chart2">
              <ox-breath-charts-print v-if="dayCount < 30" :day-count="dayCount" :from-series="serieObject['OX_BREATH_17']"
                            chart-title="呼吸数" :day-from-to="dayFromTo" :ymin="10" :ymax="35"
                            :x-title="breath17XTitle" y-title="回/分" chart-width="710" chart-height="200"/>
              <ox-breath-charts30-print v-else  :day-count="dayCount" :from-series="serieObject['OX_BREATH_3090']"
                              :day-from-to="dayFromTo" chart-title="呼吸数" :ymin="10" :ymax="35"
                              x-title="日付" y-title="回/分" chart-width="710" chart-height="200"/>
            </div>
            <div class="chart chart3" style="height:250px;">
              <div style="display: inline-block;width: 50%;">
                <breath-pie-print :day-count="dayCount" chart-title="設定流量の割合"
                           :from-series-lables="serieObject['OX_PIE']"/>
              </div>
              <div style="display: inline-block;width: 50%;">
                <breath-column-print :day-count="dayCount" chart-title="呼吸数の分布"
                              :from-series-and-category="serieObject['OX_BREATH_COLUMN_DATA']"
                              x-title="呼吸数(回/分)" y-title="頻度(回)"/>
              </div>
            </div>
          </div>
          <!-- SPO2-->
          <div v-show="chartType == 'pu'">
            <div class="chart chart3">
              <spo2-charts-print v-if="dayCount < 30" :day-count="dayCount" chart-title="血中酸素飽和度/脈拍数"
                          :from-series="serieObject['PU_SPO2_17']" :day-from-to="dayFromTo" :ymin="getSpo2YaxisPointMin(serieObject['PU_SPO2_17'],80)" :ymax="100"
                          :x-title="spo17Title" y-title="%" :colors="['#6600FF']"
                                 chart-width="710"/>
              <breath-charts30-print v-else :day-count="dayCount" chart-title="血中酸素飽和度/脈拍数" :from-series="serieObject['PU_SPO2_3090']"
                              :day-from-to="dayFromTo" :ymin="getSpo2YaxisMin(serieObject['PU_SPO2_3090'],80)" :ymax="100"
                              x-title="日付" y-title="%" :colors="['#6600FF']"
                                     chart-width="710"/>

              <pulse-charts-print v-if="dayCount < 30" :day-count="dayCount" chart-title="" style="margin-top: -20px"
                           :from-series="serieObject['PU_MYAKUHAKU_17']" :day-from-to="dayFromTo"
                           :ymin="getMyakuhakuYaxisPointMin(serieObject['PU_MYAKUHAKU_17'],60)"
                           :ymax="getMyakuhakuYaxisPointMax(serieObject['PU_MYAKUHAKU_17'],180)"
                           y-title="回/分" :colors="['#FF0000']"
                                  chart-width="710"/>
              <breath-charts30-print v-else :day-count="dayCount" chart-title="" :colors="['#FF0000']" :from-series="serieObject['PU_MYAKUHAKU_3090']"
                                     y-title="回/分" :day-from-to="dayFromTo" :ymin="getMyakuhakuYaxisMin(serieObject['PU_MYAKUHAKU_3090'],60)" :ymax="getMyakuhakuYaxisMax(serieObject['PU_MYAKUHAKU_3090'],180)"
                                     chart-width="710"/>
            </div>

            <div class="chart chart3" style="height:250px;">
              <div style="display: inline-block;width: 50%;text-align: center">
                <breath-column-print :day-count="dayCount" chart-title="血中酸素飽和度の分布"
                              :from-series-and-category="serieObject['PU_SPO2_COLUMN_DATA']" :ymin="80" :ymax="100" :tickAmount="1"
                              x-title="血中酸素飽和度(%)" y-title="頻度(回)" :colors="['#6600FF']"
                                     />
              </div>
              <div style="display: inline-block;width: 50%;text-align: right">
                <breath-column-print :day-count="dayCount" chart-title="脈拍数の分布" x-title="脈拍数(回/分)" y-title="頻度(回)"
                              :from-series-and-category="serieObject['PU_MYAKUHAKU_COLUMN_DATA']" :ymin="60" :ymax="180" :colors="['#FF0000']"
                                     />
              </div>
            </div>
          </div>
          <!-- 血圧 -->
          <div v-show="chartType== 'bl'">
            <div class="chart chart2">
              <spo2-charts-print v-if="dayCount < 30" :day-count="dayCount" chart-title="血圧" chart-height="350"
                          :from-series="serieObject['BL_17']" :day-from-to="dayFromTo" :ymin="getBLYaxisMinMax(serieObject['BL_17'], 60, true)" :ymax="getBLYaxisMinMax(serieObject['BL_17'],180,false)"
                                 :colors="['#FF33CC','#3399FF']"
              :x-title="bl17Title" y-title="mmHg"/>
              <breath-charts30-print v-else :day-count="dayCount" chart-title="血圧" chart-height="350"
                              :from-series="serieObject['BL_3090']" :day-from-to="dayFromTo" :ymin="getBL3090YaxisMinMax(serieObject['BL_3090'], 60, true)" :ymax="getBL3090YaxisMinMax(serieObject['BL_3090'],180,false)" :colors="['#FF33CC','#3399FF']"
                                     x-title="日付" y-title="mmHg"/>
            </div>

            <div class="chart chart2">
              <spo2-charts-print v-if="dayCount < 30" :day-count="dayCount" chart-title="脈拍数" chart-height="350"
                          :from-series="serieObject['BL_HEART_17']" :day-from-to="dayFromTo" :ymin="getBLYaxisMinMax(serieObject['BL_HEART_17'], 60, true)" :ymax="getBLYaxisMinMax(serieObject['BL_HEART_17'],180,false)" :colors="['#FF0000']"
                                 :x-title="bl17Title" y-title="回/分"/>
              <breath-charts30-print v-else  :day-count="dayCount" chart-title="脈拍数" chart-height="350"
                              :from-series="serieObject['BL_HEART_3090']" :day-from-to="dayFromTo" :ymin="getBL3090YaxisMinMax(serieObject['BL_HEART_3090'], 60, true)" :ymax="getBL3090YaxisMinMax(serieObject['BL_HEART_3090'],180,false)" :colors="['#FF0000']"
                              x-title="日付" y-title="回/分"/>
            </div>
          </div>

        </div>
      </div>

</template>

<script>
import moment from "moment";
import OxChartsPrint from "@/component/parts/charts/print/OxChartsPrint";
import OxCharts7Print from "@/component/parts/charts/print/OxCharts7Print";
import OxCharts90Print from "@/component/parts/charts/print/OxCharts90Print";

import OxBreathChartsPrint from "@/component/parts/charts/print/OxBreathChartsPrint";
import OxBreathCharts30Print from "@/component/parts/charts/print/OxBreathCharts30Print";
import OxSpo2ChartsPrint from "@/component/parts/charts/print/OxSpo2ChartsPrint";
import OxPulseChartsPrint from "@/component/parts/charts/print/OxPulseChartsPrint";


import BreathChartsPrint from "@/component/parts/charts/print/BreathChartsPrint";
import BreathCharts30Print from "@/component/parts/charts/print/BreathCharts30Print";
import Spo2ChartsPrint from "@/component/parts/charts/print/Spo2ChartsPrint";
import PulseChartsPrint from "@/component/parts/charts/print/PulseChartsPrint";
import BreathPiePrint from "@/component/parts/charts/print/BreathPiePrint";
import BreathColumnPrint from "@/component/parts/charts/print/BreathColumnPrint";
import axios from 'axios'

export default {
  name: 'PatientSummaryPrint',
  components: {Spo2ChartsPrint, BreathChartsPrint,BreathCharts30Print,
    OxChartsPrint,PulseChartsPrint,BreathPiePrint,BreathColumnPrint,
    OxCharts7Print,OxCharts90Print,OxBreathChartsPrint,OxBreathCharts30Print,OxSpo2ChartsPrint,OxPulseChartsPrint
  },
  data: function () {
    return {
      patientId:this.$route.params.id,
      printHeader:{},
      dayCount:1,
      chartType: "pu",
      chartTo:null,
      patientName:null,
      karuteNo:null,
      prescription:{rest:'',exercise:'',sleep:''},
      prescriptionForm:{rest:null,exercise:null,sleep:null,patientId:null},
      openPrescriptionFlg:false,
      //左側のサーまり
      measureValueSummary:{
        ox_setting:{},
        ox_breath:{},
        spo2:{},
        pr_bpm:{},
        bl_up:{},
        bl_down:{},
        bl_bpm:{}
      },
      dayFromTo:{from:0,to:0},
      serieObject: {
        // SUMMARY_OX_BREATH_17:[],
        // SUMMARY_OX_BREATH_3090:[],
        //
        OX_SETTING : [],
        //
        // SPO2_17:[],
        // SPO2_3090:[],
        // PU_MYAKUHAKU:[],
        // PU_SPO2:[],
        // BL_3090 : [],
        // BL_17 : [],
        // BL_HEART_17 : [],
        // BL_HEART_3090 : [],
        // OX_BREATH_17 : [],
        // OX_BREATH_3090 : [],
        OX_PIE : { series: [],labels: [] },
        OX_BREATH_COLUMN_DATA:{category:[],data:[]},
        PU_SPO2_COLUMN_DATA:{category: [], data:[]},
        PU_MYAKUHAKU_COLUMN_DATA:{category: [], data:[]},
      },
      widthHeight:{
        OX_SETTING_WIDTH:'185mm',

        SPO_17_WIDTH:"98%",
        SPO_17_HEIGHT:"200",

        OX_17_WIDTH:'98%',
        OX_BREATH_17_WIDTH:'98%',
        OX_PIE_WIDTH:'98%',
        OX_BREATH_COLUMN_WIDTH:'98%',
        BL_17_WIDTH:'98%'/**/,
        BL_17_HEIGHT:440/*'360'*/,

      },
      oxChartXTitle:"時刻",
      oxChartYTitle:"分",
      breath17XTitle: "時刻",
      spo17Title : "時刻",
      bl17Title : "時刻"
    }
  },
  created: function() {
    window.onbeforeprint = this.beforePrint;
    window.onafterprint = this.afterPrint;
  },
  destroyed: function() {
    window.removeEventListener('beforeprint',this.beforePrint);
    window.removeEventListener('afterprint',this.afterPrint);
  },
  methods: {
    beforePrint(){},
    afterPrint(){
      window.close();
    },
    getData(){
      // 測定値サマリー取得
      let url = `/api/measureValue/getAllChart/${this.patientId}/${this.chartType}/${this.dayCount}/${this.chartTo}?printPageFlg=true`;
      if(this.prescription !== ''){
        url = url+"&patientInfo=true"
      }
      axios.get(url)
          .then(response => {
            for (let key in response.data.result) {
              if(key == "PRINT_HEADER"){
                this.printHeader = response.data.result[key];

                this.printHeader["toDay"] = moment().format("YYYY/MM/DD");
                this.printHeader["printFormTo"] = `2020/11/30から2020/11/30(90日間)`;

                if(this.dayCount == 1){
                  this.printHeader["printFormTo"] = `${moment(this.chartTo).format("YYYY/MM/DD")}から${moment(this.chartTo).format("YYYY/MM/DD")}(${this.dayCount}日間)`;
                }else{
                  this.printHeader["printFormTo"] = `${moment(this.chartTo).add(-this.dayCount+1,'d').format("YYYY/MM/DD")}から${moment(this.chartTo).format("YYYY/MM/DD")}(${this.dayCount}日間)`;
                }
              }else if(key == 'patientSummary'){
                this.measureValueSummary = Object.assign({},this.measureValueSummary,response.data.result[key]);
              }else if(key == 'patientInfo') {
                this.prescription = response.data.result[key]["prescription"];
                this.patientName = response.data.result[key]["patientName"];
                this.karuteNo = response.data.result[key]["karuteNo"];
              }else{
                // this.serieObject[key] = response.data.result[key];
                this.$set(this.serieObject, key, response.data.result[key]);
              }
            }
            this.dayFromTo.from =
                moment(`${this.chartTo} 00:00:00`).add(-this.dayCount+1,"d").format("YYYY-MM-DD HH:mm:ss");
            this.dayFromTo.to =
                moment(`${this.chartTo} 00:00:00`).add(1,"d").format("YYYY-MM-DD HH:mm:ss");

            if(this.chartType == "summary" ||this.chartType == "ox" ){
              if(this.dayCount == 1){
                this.oxChartXTitle= "時刻";
                this.oxChartYTitle = "分";
              }else{
                this.oxChartXTitle= "日付";
                this.oxChartYTitle = "時刻";
              }
            }

            if(this.chartType == "summary" || this.chartType == "ox" || this.chartType == "pu" ){
              if(this.dayCount == 1){
                this.breath17XTitle= "時刻";
                this.spo17Title = "時刻";
                this.bl17Title = "時刻";
              }else{
                this.breath17XTitle= "日付";
                this.spo17Title  = "日付";
                this.bl17Title = "日付";
              }
            }

            setTimeout(()=>{
              window.print();
            },1000);
          }).catch(error => {console.log(error)})
          .finally(() => {});
    },getMeasureValueSummary(){
      axios.get(`/api/get_measure_value_summary/${this.patientId}/${this.dayCount}/${this.chartTo}`)
          .then(response => {

            this.measureValueSummary = Object.assign({},this.measureValueSummary,response.data.result);

          }).catch(error => {console.log(error)})
          .finally(() => {
          });
    },
    //患者情報取得 カルテIDなど
    // getPatientInfo(){
    //    axios.get(`/api/patient_info/${this.patientId}`).then(response => {
    //     if(response.data.result){
    //       this.prescription = response.data.result.prescription;
    //       this.patientName = response.data.result.patientName;
    //       this.karuteNo = response.data.result.karuteNo;
    //     }
    //   }).catch(error => console.log(error)
    //   ).finally(() => this.isLoading = false);
    // },
    getBLYaxisMinMax(ser, defaultValue, minFlg) {
      if(ser === undefined || ser.length == 0){
        return defaultValue;
      }
      let dataList = [];

      for (let j = 0; j < ser.length; j++) {

        for (let i = 0; i < ser[j]["data"].length; i++) {
          let d = ser[j]["data"][i];

          let data = d[1];
          if (data > 0) {
            dataList.push(data);
          }
        }
      }
      if (dataList.length < 1) {
        return defaultValue;
      }
      let result = 0;
      if (minFlg) {
        let min = dataList.reduce(function (p, v) {
          return (p < v ? p : v);
        }) - 5;
        result =  min > defaultValue ? defaultValue : min;
      } else {
        let max = dataList.reduce(function (p, v) {
          return (p > v ? p : v);
        }) + 5;
        result =  max < defaultValue ? defaultValue : max;
      }
      // console.log("dataListResult",result);
      return result;
    },getBL3090YaxisMinMax(ser, defaultValue, minFlg) {
      if(ser === undefined || ser.length == 0){
        return defaultValue;
      }
      let dataList = [];

      for (let j = 0; j < ser.length; j++) {

        for (let i = 0; i < ser[j]["data"].length; i++) {
          let d = ser[j]["data"][i];

          for (let k = 0; k < d["y"].length; k++) {
            let data = d["y"][k];
            if (data > 0) {
              dataList.push(data);
            }
          }
        }
      }
      if (dataList.length < 1) {
        return defaultValue;
      }
      let result = 0;
      if (minFlg) {
        let min = dataList.reduce(function (p, v) {
          return (p < v ? p : v);
        }) - 5;
        result =  min > defaultValue ? defaultValue : min;
      } else {
        let max = dataList.reduce(function (p, v) {
          return (p > v ? p : v);
        }) + 5;
        result =  max < defaultValue ? defaultValue : max;
      }
      console.log("dataListResult",minFlg,result);
      return result;
    },
    getSpo2YaxisMin(ser,defaultMin){
      if(ser === undefined || ser.length == 0){
        return defaultMin;
      }
      let minList = [];
      let maxList = [];
      for(let i=0;i<ser[0]["data"].length;i++){
        let d = ser[0]["data"][i];

        let min = d["y"][0];
        let max = d["y"][1];
        if(min > 0){
          minList.push(min);
        }
        if(max > 0){
          maxList.push(max);
        }
      }
      if(minList.length < 1){
        return defaultMin;
      }

      let min = minList.reduce(function (p, v) {
        return ( p < v ? p : v );
      })
      return min-5;
    },
    getSpo2YaxisPointMin(ser,defaultMin){
      if(ser === undefined || ser.length == 0){
        return defaultMin;
      }
      let list = [];

      for(let i=0;i<ser[0]["data"].length;i++){
        let d = ser[0]["data"][i][1];
        if(d > 0){
          list.push(d);
        }
      }
      if(list.length < 1){
        return defaultMin;
      }

      let min = list.reduce(function (p, v) {
        return ( p < v ? p : v );
      });
      return min-5;
    },
    getMyakuhakuYaxisMin(ser,defaultMin){
      if(ser === undefined || ser.length == 0){
        return defaultMin;
      }
      let minList = [];
      let maxList = [];
      for(let i=0;i<ser[0]["data"].length;i++){
        let d = ser[0]["data"][i];

        let min = d["y"][0];
        let max = d["y"][1];
        if(min > 0){
          minList.push(min);
        }
        if(max > 0){
          maxList.push(max);
        }
      }
      if(minList.length < 1){
        return defaultMin;
      }

      let min = minList.reduce(function (p, v) {
        return ( p < v ? p : v );
      })
      return min-5;
    },
    getMyakuhakuYaxisMax(ser,defaultMax){
      if(ser === undefined || ser.length == 0){
        return defaultMax;
      }
      let minList = [];
      let maxList = [];
      for(let i=0;i<ser[0]["data"].length;i++){
        let d = ser[0]["data"][i];

        let min = d["y"][0];
        let max = d["y"][1];
        if(min > 0){
          minList.push(min);
        }
        if(max > 0){
          maxList.push(max);
        }
      }
      if(minList.length < 1){
        return defaultMax;
      }
      let max= maxList.reduce(function (p, v) {
        return ( p > v ? p : v );
      })
      return max+5;
    },
    getMyakuhakuYaxisPointMin(ser,defaultMin){
      if(ser === undefined || ser.length == 0){
        return defaultMin;
      }
      let list = [];

      for(let i=0;i<ser[0]["data"].length;i++){
        let d = ser[0]["data"][i][1];
        if(d > 0){
          list.push(d);
        }
      }
      if(list.length < 1){
        return defaultMin;
      }
      let min= list.reduce(function (p, v) {
        return ( p < v ? p : v );
      })
      return min-5;
    },
    getMyakuhakuYaxisPointMax(ser,defaultMax){
      if(ser === undefined || ser.length == 0){
        return defaultMax;
      }
      let list = [];

      for(let i=0;i<ser[0]["data"].length;i++){
        let d = ser[0]["data"][i][1];
        if(d > 0){
          list.push(d);
        }
      }
      if(list.length < 1){
        return defaultMax;
      }
      let max= list.reduce(function (p, v) {
        return ( p > v ? p : v );
      })
      return max+5;
    },
  },
  computed:{
    souchakuritsu(){
      // let result = (this.measureValueSummary.ox_breath["hour"] / this.measureValueSummary.ox_setting["hour"] * 100).toFixed(1);
      // if(isNaN(result)){
      //   return "-";
      // }
      // return Number(result).toString()+"%";
      return this.measureValueSummary.ox_breath["rate"];
    },
    oxHourMark(){
      //return isNaN(this.measureValueSummary.ox_setting["hour"]) ? "" : "h";
      // return isNaN(this.measureValueSummary["kadouJikan"]) ? "" : "h";
      return "";

    },
    oxBreathHourMark(){
      //return isNaN(this.measureValueSummary.ox_breath["hour"]) ? "" : "h";
      // return isNaN(this.measureValueSummary["souchakuJikan"]) ? "" : "h";
      return "";
    }
  },
  mounted(){
    this.patientId = this.$route.params.patientId;
    this.chartTo = this.$route.params.chartTo;
    this.chartType = this.$route.params.chartType;
    this.dayCount = this.$route.params.dayCount;

    //グラフ用のデータシュトック
    this.getData();
  }
}
</script>

<style scoped>
  .solid tr,td {
    border:solid 1px black;
  }

  html,body{
    background: white;
    height: 205mm;
    width: 297mm;
    margin:0;
    padding:0;
  }
.my-container{
  width:297mm;
  height:203mm;
  /*background: #0dAAAA;*/
  margin:0;
  padding:0;
  padding-top: 2mm;
  /*border: solid 2px black;*/
}
.tool-bar{
  font-size: 3.5mm;
  color:black;
}

.left{
  width:107mm;
  height:197mm;
  /*background: #FF85b9;*/
  float:left;
  margin:0;
  padding:0;
  padding-left: 3mm;
}
.right{
  width:188mm;
  height:197mm;
  /*background: yellow;*/
  float:left;
  margin:0;
  padding:0;
}

.chart.half {
  width:100%;
  float:left;
}

.half {
    width:90mm;
    float:left;
}
.summary-table td{
  font-size: 3mm;
  color:black;
}

.summary-table td.header{
  font-size: 3mm;
  color:black;
  font-weight: bold;
}
.patient-info{
  font-size: 4mm;
  color:black;
}

@page {
  margin: 5px;
  size: A4 landscape;
}
/*}*/

</style>